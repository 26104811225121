import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frontend-utilities-for-helsinki-uis"
    }}>{`Frontend utilities for Helsinki UIs`}</h1>
    <p>{`Common code used in several Helsinki UIs has `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/helsinki-utils-js"
      }}>{`its own repo`}</a>{`.
For Helsinki UI components, the location to go to is the `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/helsinki-design-system"
      }}>{`Helsinki Design System`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      